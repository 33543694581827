import { useUi, Breakpoints } from './use-ui'

export type BadgeColors =
  | 'dark'
  | 'light'
  | 'grey'
  | 'danger'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'none'
export type BadgeSizes = 'xs' | 'small' | 'medium' | 'large'
export type BadgePadding = 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl'
export type BadgeType = 'round' | 'square' | 'box'

export interface BadgeProps extends Omit<React.HTMLProps<HTMLSpanElement>, 'size' | 'color' | 'type'> {
  color?: BadgeColors
  size?: Breakpoints<BadgeSizes>
  type?: BadgeType
  padding?: Breakpoints<BadgePadding>
  className?: string
  children: React.ReactNode
}

export const Badge = ({
  color = 'primary',
  size = 'medium',
  type = 'round',
  padding,
  className,
  children,
  ...htmlProps
}: BadgeProps) => {
  if (!padding) {
    padding = size
  }

  const colors = {
    default: ``,
    dark: 'bg-black text-white',
    light: 'bg-selphBlue-200 text-selphBlue-600 bg-opacity-30',
    grey: 'bg-gray-600 text-white',
    danger: 'bg-dangerRed text-selphGrey-50',
    info: 'bg-cyan-100 text-cyan-800',
    primary: 'bg-selphBlue-200 text-selphGrey-50',
    secondary: 'bg-gray-100 text-gray-800',
    success: 'bg-selphBlue-300 text-selphGrey-50',
    warning: 'bg-warningYellow text-selphGrey-50',
    indigo: 'bg-indigo-100 text-indigo-800',
    purple: 'bg-purple-100 text-purple-800',
    pink: 'bg-hotPink-500 text-white',
    none: '',
  }

  const sizes = {
    default: 'inline-flex items-center',
    xs: 'text-xs', // Tailwind: sm:text-xs  md:text-xs  lg:text-xs  xl:text-xs
    small: 'text-sm', // Tailwind: sm:text-sm  md:text-sm  lg:text-sm  xl:text-sm
    medium: 'text-base', // Tailwind: sm:text-base  md:text-base  lg:text-base  xl:text-base
    large: 'text-lg', // Tailwind: sm:text-lg  md:text-lg  lg:text-lg  xl:text-lg
  }

  const paddings = {
    xs: 'px-1.5 py-0.5 ', // Tailwind: sm:px-1.5 sm:py-0.5  md:px-1.5 md:py-0.5  lg:px-1.5 lg:py-0.5  xl:px-1.5 xl:py-0.5
    small: 'px-2.5 py-0.5', // Tailwind: sm:px-2.5 sm:py-0.5  md:px-2.5 md:py-0.5  lg:px-2.5 lg:py-0.5  xl:px-2.5 xl:py-0.5
    medium: 'px-3 py-1', // Tailwind: sm:px-3 sm:py-1  md:px-3 md:py-1  lg:px-3 lg:py-1  xl:px-3 xl:py-1
    large: 'px-3.5 py-1.5', // Tailwind: sm:px-3.5 sm:py-1.5  md:px-3.5 md:py-1.5  lg:px-3.5 lg:py-1.5  xl:px-3.5 xl:py-1.5
    xl: 'px-5 py-3', // Tailwind: sm:px-5 sm:py-3  md:px-5 md:py-3  lg:px-5 lg:py-3  xl:px-5 xl:py-3
    xxl: 'px-7 py-5', // Tailwind: sm:px-7 sm:py-5  md:px-7 md:py-5  lg:px-7 lg:py-5  xl:px-7 xl:py-5
  }

  const types = {
    default: '',

    round: 'rounded-full',
    box: 'rounded-xl',
    square: 'rounded',
  }

  const ui = useUi({
    styles: {
      color: { options: colors, selected: color },
      sizes: { options: sizes, selected: size },
      types: { options: types, selected: type },
      paddings: { options: paddings, selected: padding },
    },
    name: 'Badge',
    className,
  })

  return (
    <span {...ui.attributes} {...htmlProps} className={ui.className}>
      {children}
    </span>
  )
}

Badge.displayName = 'Badge'

export default Badge
